.pdf-viewer-block {
  margin-bottom: 50px;
}

.pdf-viewer-block.click-to-download .pdf-viewer {
  background-color: transparent;
}

.pdf-viewer {
  position: relative;
  overflow: hidden;
  background-color: #e5e5e5;
}

.pdf-toolbar {
  display: flex;
  width: 100%;
  height: 32px;
  align-items: center;
  justify-content: space-between;
  padding: 0 6px;
  background-color: #383838;
  color: #fff;
}

.pdf-toolbar-bottom {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

.pdf-toolbar p {
  margin: 0 !important;
  font-size: 14px;
}

.pdf-toolbar .scale-ratio {
  position: relative;
  bottom: 2px;
  left: 7px;
  display: inline-block;
  font-size: 12px;
}

.pdf-toolbar-btn {
  padding-right: 5px;
  padding-left: 5px;
  border: none;
  margin-right: 3px;
  margin-left: 3px;
  background-color: transparent;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
}

.pdf-toolbar-btn:hover {
  border: #000;
  background-color: rgba(0, 0, 0, 0.5);
}

.pdf-toolbar-btn svg {
  margin-top: 2px;
}

.disabled-btn {
  opacity: 0.4;
}

.disabled-btn:hover {
  border: transparent;
  background-color: transparent;
}

.scale-separator {
  display: inline-block;
  width: 1px;
  padding: 8px 0;
  margin-top: 3px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 0 1px hsla(0, 0%, 100%, 0.08);
}

.pdf-title {
  margin-bottom: 1em !important;
}

.pdf-toolbar .pdf-pages {
  margin-right: 1em !important;
}

.pdf-block-download-overlay {
  position: 'absolute';
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgb(204, 204, 204, 0.3);
}

.pdf-block-download-overlay .icon-wrapper {
  text-align: center;
}

.pdf-block-download-overlay .icon-wrapper .btn-icon {
  display: inline-block;
  /* padding: 0.3em; */
  /* border-radius: 10%; */
}

.pdf-block-download-overlay .icon-wrapper .btn-icon svg {
  margin: 0px !important;
}
